.button-group {
    display: flex;
    flex-wrap: wrap;
}

.button-group > button {
    position: relative;
    z-index: 10;
    margin: -1px;
}

.button-group > button:first-child,
.button-group > button:not(:first-child):first-child {
    border-top-left-radius: 0.375rem; /* rounded-l-md */
    border-bottom-left-radius: 0.375rem; /* rounded-l-md */
}

.button-group > button:not(:first-child):last-child,
.button-group > button:last-child {
    border-top-right-radius: 0.375rem; /* rounded-r-md */
    border-bottom-right-radius: 0.375rem; /* rounded-r-md */
}
