.react-datetime-picker__inputGroup__input {
    border: none !important;
}

.react-datetime-picker__wrapper {
    border: none !important;
    border-radius: 4px;
    background-color: #fff;
}

.react-datetime-picker__inputGroup__divider {
    background-color: #fff;
}
